import Head from "next/head";
import styles from "../styles/Home.module.css";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import { isMobile } from "react-device-detect";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export default function Home() {
  const defaultFormValues = {
    frenchGrade: "",
    safeWithPads: false,
    dangerPoint: "",
    numberOfPads: 0,
  };

  const [frenchGrade, setfrenchGrade] = useState(defaultFormValues.frenchGrade);
  const [safeWithPads, setSafeWithPads] = useState(
    defaultFormValues.safeWithPads,
  );
  const [dangerPoint, setDangerPoint] = useState(defaultFormValues.dangerPoint);
  const [numberOfPads, setNumberOfPads] = useState(
    defaultFormValues.numberOfPads,
  );

  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);

  const validationSchema = Yup.object().shape({
    frenchGrade: Yup.string().required("Please enter a french grade"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  React.useEffect(() => {
    if (isMobile && result !== null) {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [result]);
  React.useEffect(() => {
    if (dangerPoint == 0) {
      setNumberOfPads(0);
      setSafeWithPads(false);
    }
  }, [dangerPoint]);

  async function getResult() {
    setResult(null);
    setIsLoading(true);
    await fetch("/api/results", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        frenchGrade: frenchGrade,
        dangerPoint: dangerPoint,
        numberOfPads: numberOfPads,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setResult(data[0].Result);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(false);
      });
  }

  function resetValues() {
    setfrenchGrade(defaultFormValues.frenchGrade);
    setDangerPoint(defaultFormValues.dangerPoint);
    setSafeWithPads(defaultFormValues.safeWithPads);
    setNumberOfPads(defaultFormValues.numberOfPads);

    setResult(null);
    reset(() => ({
      defaultFormValues,
    }));
  }

  const handleChange = (show) => {
    if (show) {
      setNumberOfPads(0);
      setSafeWithPads(safeWithPads);
    } else {
      setNumberOfPads(0);
      setSafeWithPads(!safeWithPads);
    }
  };

  const launchDate = new Date("April 3, 2023 08:30:00");
  const currentDate = new Date();
  function getNumberOfPadsDisplay() {
    switch (numberOfPads) {
      case "0":
        return "no pads";
      case "0.5":
        return "1 - 3 pads";
      case "1":
        return "4 - 6 pads";
      case "2":
        return "6 or more pads";
      default:
        return "no pads";
    }
  }

  const shareMessage = `I got ${result} from ${frenchGrade} with ${dangerPoint} danger points using ${getNumberOfPadsDisplay()}. Do you agree?`;

  return (
    <div className={styles.container}>
      <Head>
        <title>eGrader | The E-Grade Converter</title>
        <link rel="icon" href="/favicon.ico" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-14QB5QWYMJ"
        ></script>

        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-14QB5QWYMJ', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
      </Head>
      {launchDate >= currentDate && (
        <>
          <div className="main-bg grid grid-cols-12 minHeight ">
            <div className="col-span-12 h-full mainwrapper">
              <div className="main-content flex justify-center minHeight text-center">
                <div class="w-full justify-center m-auto justify-items-center	">
                  <img
                    className="mb-10 m-auto"
                    width="280"
                    src="/logo.svg"
                    alt="Logo"
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {launchDate <= currentDate && (
        <>
          <div className=" grid grid-cols-12 minHeight ">
            <div class="main-bg"></div>
            <div className="col-span-12 minHeight lg:h-auto lg:col-span-7 mainwrapper">
              <div className="main-content pt-10 px-10 pb-12 md:px-30  lg:p-20 lg:px-20">
                <img className="mb-10" src="/logo.svg" alt="Logo" />

                <div>
                  <h1>The E-Grade Converter</h1>
                  <p>
                    Enter the following information to gain an impression of the
                    E Grade of your recent climbs.
                  </p>
                  <form
                    className={styles.form}
                    onSubmit={handleSubmit(getResult)}
                  >
                    <div className="form-item ">
                      <div className="lg:flex justify-between items-center">
                        <label
                          className="whitespace-nowrap mr-7 lg:mb-0 font-bold"
                          htmlFor="frenchGrade"
                        >
                          French Grade
                        </label>
                        <select
                          className="rounded-md p-3 bg-white"
                          {...register("frenchGrade")}
                          value={frenchGrade}
                          onChange={(e) => {
                            setfrenchGrade(e.target.value);
                          }}
                        >
                          <option value="">-</option>
                          <option value="6a">6a</option>
                          <option value="6a+">6a+</option>
                          <option value="6b">6b</option>
                          <option value="6b+">6b+</option>
                          <option value="6c">6c</option>
                          <option value="6c+">6c+</option>
                          <option value="7a">7a</option>
                          <option value="7a+">7a+</option>
                          <option value="7b">7b</option>
                          <option value="7b+">7b+</option>
                          <option value="7c">7c</option>
                          <option value="7c+">7c+</option>
                          <option value="8a">8a</option>
                          <option value="8a+">8a+</option>
                          <option value="8b">8b</option>
                          <option value="8b+">8b+</option>
                          <option value="8c">8c</option>
                          <option value="8c+">8c+</option>
                          <option value="9a">9a</option>
                          <option value="9a+">9a+</option>
                          <option value="9b">9b</option>
                          <option value="9b+">9b+</option>
                          <option value="9c">9c</option>
                          <option value="9c+">9c+</option>
                          <option value="10a">10a</option>
                          <option value="10a+">10a+</option>
                          <option value="10b">10b</option>
                          <option value="10b+">10b+</option>
                          <option value="10c">10c</option>
                          <option value="10c+">10c+</option>
                          <option value="11a">11a</option>
                          <option value="11a+">11a+</option>
                          <option value="11b">11b</option>
                          <option value="11b+">11b+</option>
                          <option value="11c">11c</option>
                          <option value="11c+">11c+</option>
                        </select>
                      </div>
                      {errors.frenchGrade?.message && (
                        <div className="rounded-md mt-2 invalid-feedback bg-red-300 px-2 py-1  text-red-900 ">
                          {errors.frenchGrade?.message}
                        </div>
                      )}
                    </div>
                    <div className="form-item ">
                      <div className="lg:flex justify-between items-center">
                        <label className="whitespace-nowrap mr-8 lg:mb-0 font-bold">
                          Danger Point
                        </label>
                        <select
                          className="rounded-md p-3 bg-white"
                          {...register("dangerPoint")}
                          value={dangerPoint}
                          onChange={(e) => {
                            setDangerPoint(e.target.value);
                          }}
                        >
                          <option value="">-</option>
                          <option value="0">0 = Bolted Sport climb</option>
                          <option value="0.5">0.5 = Well protected Trad</option>
                          <option value="1">1 = Standard trad</option>
                          <option value="1.5">1.5 = Slightly Runout</option>
                          <option value="2">2 = Runout</option>
                          <option value="2.5">2.5 = Very Runout</option>
                          <option value="3">3 = Dangerous</option>
                          <option value="3.5">3.5 = Very Dangerous</option>
                          <option value="4">4 = Extremely Dangerous</option>
                        </select>
                      </div>
                      <div className="mt-3 tooltip">
                        {dangerPoint == 0 && dangerPoint != "" && (
                          <p>
                            0 = Bolted - A “Sport” climb - Injury Potential -
                            Extremely unlikely
                          </p>
                        )}
                        {dangerPoint == 0.5 && (
                          <p>
                            0.5 = Well protected Trad - Regularly spaced, easy
                            to place “bomber” gear (i.e a splitter crack).
                            Unlikely to be any more dangerous than a sport
                            route, though energy is lost placing the protection.
                            - Injury Potential - Very Unlikely
                          </p>
                        )}
                        {dangerPoint == 1 && (
                          <p>
                            1 = “Standard” Trad - Regularly spaced good gear,
                            but requires knowledge of different types of trad
                            gear to safely protect the climbing. - Injury
                            Potential - Unlikely
                          </p>
                        )}
                        {dangerPoint == 1.5 && (
                          <p>
                            1.5 = Slightly Runout - Medium spaced good gear or
                            shorter sections with medium quality gear. Certain
                            sections might feel committing. - Injury Potential -
                            Possible
                          </p>
                        )}
                        {dangerPoint == 2 && (
                          <p>
                            2 = Runout - Longer sections between good gear, or
                            shorter sections with poor gear, resulting in a
                            potentially long fall, which is more likely scary
                            than dangerous. - Injury Potential - Moderate
                          </p>
                        )}
                        {dangerPoint == 2.5 && (
                          <p>
                            2.5 = Very Runout - Some very long run-outs with
                            potential for injury or ground fall from several
                            moves. Advanced and specialist ropework / belay
                            techniques are essential to minimize injury! -
                            Injury Potential - Likely
                          </p>
                        )}
                        {dangerPoint == 3 && (
                          <p>
                            3 = Dangerous - Ground fall likely from the crux.
                            Injury is likely even if advanced and specialist
                            ropework / belay techniques are used. - Injury
                            Potential - Very Likely
                          </p>
                        )}
                        {dangerPoint == 3.5 && (
                          <p>
                            3.5 = Very Dangerous - A fall from the crux is
                            extremely likely to cause serious injury or death -
                            Injury Potential - Extremely Likely
                          </p>
                        )}
                        {dangerPoint == 4 && (
                          <p>
                            4 = Extreme Danger - Fall “guaranteed” to result in
                            serious injury or death. Very rare - Injury
                            Potential - Guaranteed
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="form-item">
                      {dangerPoint > 0 && (
                        <>
                          <div className=" items-center mb-8">
                            <p className="font-bold w-full mb-0 text-white text-lg">
                              Do pads make the route safer?
                            </p>
                            <div className="mt-5 flex">
                              <button
                                type="button"
                                className={
                                  safeWithPads
                                    ? "multiple-choice-selector"
                                    : "multiple-choice-selector--selected"
                                }
                                onClick={() => setSafeWithPads(true)}
                              >
                                Yes
                                {safeWithPads && (
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    className="ml-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M21 6.99997L9 19L3.5 13.5L4.91 12.09L9 16.17L19.59 5.58997L21 6.99997Z"
                                      fill="white"
                                    />
                                  </svg>
                                )}
                              </button>
                              <button
                                type="button"
                                className={
                                  !safeWithPads
                                    ? "multiple-choice-selector"
                                    : "multiple-choice-selector--selected"
                                }
                                onClick={() => {
                                  setNumberOfPads(0);
                                  setSafeWithPads(false);
                                }}
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      <div className={safeWithPads ? "visible" : "hidden"}>
                        <label className="mb-3 font-bold text-white">
                          Number of Pads
                        </label>
                        <ul className=" md:grid md:grid-cols-2 xl:grid-cols-4 items-center w-full text-sm font-medium text-gray-900 bg-white  border rounded-md border-gray-200 selector ">
                          <li
                            className={
                              numberOfPads == 0
                                ? "item-selector--selected"
                                : "item-selector"
                            }
                          >
                            <div className="flex items-center pl-3 h-full">
                              <input
                                id="horizontal-list-radio-license"
                                type="radio"
                                value={0}
                                checked={numberOfPads == 0}
                                onChange={(e) => {
                                  setNumberOfPads(e.target.value);
                                }}
                                name="pads"
                                className="radio w-4 h-4 text-primary-500 bg-gray-100 border-gray-300 focus:ring-yellow-500 "
                              />
                              <label
                                htmlFor="horizontal-list-radio-license"
                                className={
                                  numberOfPads == 0
                                    ? "radio-button-label py-3 ml-2 w-full text-md font-medium "
                                    : "text-black radio-button-label py-3 ml-2 w-full text-md font-medium "
                                }
                              >
                                No Pads
                                <br />
                                <span
                                  className={
                                    numberOfPads == 0
                                      ? "text-sm mb-0 "
                                      : "text-black text-sm mb-0"
                                  }
                                >
                                  0 pads
                                </span>
                              </label>
                            </div>
                          </li>
                          <li
                            className={
                              numberOfPads == 0.5
                                ? "item-selector--selected"
                                : "item-selector"
                            }
                          >
                            <div className="flex items-center px-3">
                              <input
                                id="horizontal-list-radio-id"
                                type="radio"
                                value={0.5}
                                checked={numberOfPads == 0.5}
                                onChange={(e) => {
                                  setNumberOfPads(e.target.value);
                                }}
                                name="pads"
                                className="radio w-4 h-4 text-blue-600 bg-gray-100  sm:border-r border-gray-300 focus:ring-blue-500"
                              />
                              <label
                                htmlFor="horizontal-list-radio-id"
                                className={
                                  numberOfPads == 0.5
                                    ? "radio-button-label py-3 ml-2 w-full text-md font-medium "
                                    : "text-black radio-button-label py-3 ml-2 w-full text-md font-medium "
                                }
                              >
                                Lightly Padded <br />{" "}
                                <span
                                  className={
                                    numberOfPads == 0.5
                                      ? "text-sm mb-0 "
                                      : "text-black text-sm mb-0"
                                  }
                                >
                                  1 - 3 pads
                                </span>
                              </label>
                            </div>
                          </li>
                          <li
                            className={
                              numberOfPads == 1
                                ? "item-selector--selected"
                                : "item-selector"
                            }
                          >
                            <div className="flex items-center px-3">
                              <input
                                id="horizontal-list-radio-id1"
                                type="radio"
                                checked={numberOfPads == 1}
                                value={1}
                                onChange={(e) => {
                                  setNumberOfPads(e.target.value);
                                }}
                                name="pads"
                                className="radio w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                              />
                              <label
                                htmlFor="horizontal-list-radio-id1"
                                className={
                                  numberOfPads == 1
                                    ? "radio-button-label py-3 ml-2 w-full text-md font-medium "
                                    : "text-black radio-button-label py-3 ml-2 w-full text-md font-medium "
                                }
                              >
                                Medium Padded <br />{" "}
                                <span
                                  className={
                                    numberOfPads == 1
                                      ? "text-sm mb-0"
                                      : "text-black text-sm mb-0"
                                  }
                                >
                                  4 - 6 pads
                                </span>
                              </label>
                            </div>
                          </li>

                          <li
                            className={
                              numberOfPads == 2
                                ? "item-selector--selected"
                                : "item-selector"
                            }
                          >
                            <div className="flex items-center px-3">
                              <input
                                id="horizontal-list-radio-id2"
                                type="radio"
                                checked={numberOfPads == 2}
                                value={2}
                                onChange={(e) => {
                                  setNumberOfPads(e.target.value);
                                }}
                                name="pads"
                                className="radio w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                              />
                              <label
                                htmlFor="horizontal-list-radio-id2"
                                className={
                                  numberOfPads == 2
                                    ? "radio-button-label py-3 ml-2 w-full text-md font-medium "
                                    : "text-black radio-button-label py-3 ml-2 w-full text-md font-medium "
                                }
                              >
                                Heavily Padded <br />{" "}
                                <span
                                  className={
                                    numberOfPads == 2
                                      ? "text-sm mb-0"
                                      : "text-black text-sm mb-0 "
                                  }
                                >
                                  6 or more pads
                                </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {isLoading && (
                      <button
                        className="w-full py-6 text-sm uppercase font-semibold bg-primary-500 hover:bg-primary-600"
                        disabled
                      >
                        <div className="mx-auto" role="status">
                          <svg
                            className="inline mr-2 w-8 h-8 text-gray-200 animate-spin fill-primary-400"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    )}
                    {!isLoading && (
                      <>
                        <div className="flex flex-col-reverse xl:flex xl:flex-row">
                          <button
                            type="button"
                            onClick={resetValues}
                            className="w-full py-6 text-sm uppercase font-semibold  hover:text-gray-300"
                          >
                            Start over
                          </button>
                          <button
                            className="rounded-md w-full py-6 text-sm uppercase font-semibold bg-primary-500 hover:bg-primary-700"
                            type="submit"
                          >
                            Convert Grade
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
                {result && !isLoading && (
                  <div id="results">
                    <div className="card lg:col-start-8 lg:col-end-13 visible lg:hidden">
                      <h2>Overall E Grade</h2>
                      <p className="stat">{result}</p>
                      <div className="mt-3">
                        <TwitterShareButton
                          className="mt-3 link underline"
                          url="https://egrader.co.uk/"
                          title={shareMessage}
                        >
                          Share on Twitter
                        </TwitterShareButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="text-sm absolute w-full  text-center bottom-4 font-semibold  hover:text-gray-300">
                <a
                  href="https://drive.google.com/file/d/1nxDxDr3ueFn7NTIUuD0mBgFdz6PNUEVk/view?usp=sharing"
                  target="_blank"
                >
                  About eGrader
                </a>
              </div>
            </div>

            <div
              id="results"
              className="col-span-5 hidden lg:block lg:px-10 lg:col-start-8 lg:col-end-13"
            >
              {result && !isLoading && (
                <div className="flex flex-col items-center  justify-center h-full">
                  <div className="card  lg:flex w-screen lg:w-auto">
                    <h2>Overall E Grade</h2>
                    <p className="stat">{result}</p>
                    <TwitterShareButton
                      className="mt-3 link underline"
                      url="https://egrader.co.uk/"
                      title={shareMessage}
                    >
                      Share on Twitter
                    </TwitterShareButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
